import { MdCurrencyRupee } from "react-icons/md";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import BagsMobile from "../../assets/img/bagsmobile.jpg";
import BagsImg from "../../assets/img/bags.jpg";
import { store } from "../../Helper/Context/Store";
import BlueMobile from "../../assets/img/All-Four-Product/BLUE-M.jpg";
import Blue from "../../assets/img/All-Four-Product/BLUE.jpg";
import OrangeMobile from "../../assets/img/All-Four-Product/ORANGE-M.jpg";
import Orange from "../../assets/img/All-Four-Product/ORANGE.jpg";
import PinkMobile from "../../assets/img/All-Four-Product/PINK-M.jpg";
import Pink from "../../assets/img/All-Four-Product/PINK.jpg";
import YellowMobile from "../../assets/img/All-Four-Product/YELLOW-M.jpg";
import Yellow from "../../assets/img/All-Four-Product/YELLOW.jpg";
import { Skeleton } from "antd";
import "./BagsSection.css";
import ProductCard from "../ProductCard/ProductCard";
export default function BagsSection({ bagData, loadingState }) {
  const { cartItems } = useContext(store);

  const Products = [
    {
      img: Pink,
      mobileimg: PinkMobile,
      style: "flower-main-box",
      addbtnstyle: "flower-add-btn",
      category: "Perfume",
      productname: "Flower Power",
    },
    {
      img: Orange,
      mobileimg: OrangeMobile,
      style: "orange-main-box",
      addbtnstyle: "orange-add-btn",
      category: "Perfume",
      productname: "Orange Blossom",
    },
    {
      img: Blue,
      mobileimg: BlueMobile,
      style: "aqua-main-box",
      addbtnstyle: "aqua-add-btn",
      category: "Perfume",
      productname: "Aqua Blue",
    },
    {
      img: Yellow,
      mobileimg: YellowMobile,
      style: "timer-main-box",
      addbtnstyle: "timber-add-btn",
      category: "Perfume",
      productname: "Timber Gold",
    },
  ];

  return (
    <>
      {loadingState === true ? (
        <>
          <div className="container-fluid product-section product-section-skeleton">
            <Skeleton.Image className="w-100 h-100 d-none d-lg-block" />
            <div className="row">
              <div className="col-12 d-block d-lg-none">
                <div className="four-product-card">
                  <div className="image mb-2 w-100">
                    <Skeleton.Image className="img-fluid w-100 h-100 aspect-ratio position-static" />
                  </div>
                  <div className="content">
                    <p className="price mb-0">
                      <Skeleton.Input />
                    </p>
                    {/* <button className={`btn ${bannerStyle}  default-btn-one cursor-pointer mx-0`}> */}

                    <span className="cursor-pointer">
                      <Skeleton.Button
                        classNames={`btn default-btn-one cursor-pointer mx-0`}
                      />
                    </span>
                    {/* </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container-fluid bags-section">
          <img
            src={BagsImg}
            className="w-100 d-none d-lg-block"
            alt="Bags Kaawaii Cosmetics"
          />
          <img
            src={BagsMobile}
            className="w-100 d-block d-lg-none"
            alt="Bags Kaawaii Cosmetics"
          />
          <div className="container h-100">
            <div className="row h-100 align-items-center justify-content-center">
              <div className="col-lg-6 pt-5 my-5 pe-lg-5 me-lg-5"></div>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                // autoplay="false"
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                // }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                slidesPerView={1}
                spaceBetween={20}
                loop={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper pb-4 pb-lg-5 pe-lg-5">
                {
                  bagData.length > 0 ?
                    (
                      bagData.map((bag) => {
                        const isInCart = cartItems.find(
                          (item) => item.fld_variantid === bag.fld_variantid
                        );
                        return (

                          <SwiperSlide key={bag.fld_variantid}>
                            <ProductCard productData={bag} isInCart={isInCart} />
                          </SwiperSlide>

                        );
                      })
                    )
                    :
                    "No items found"
                }
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
